nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10000;
    position: fixed;
    width: 100%;
    background-color: var(--background-opaque);

    * {
        z-index: 10000;
    }

    .header {
        .open {
            display: none;
        }
        
        .logo {
            margin-left: 25px;
            
            img {
                height: 25px;
            }
        }
    }

    .links {
        display: flex;
        width: 80%;
        box-sizing: border-box;
        justify-content: space-around;
        align-items: center;
        
        & > * {
            padding: 10px;
        }

        .link-container {
            position: relative;

            @media (min-width:320px) and (max-device-width : 480px) {
                &:last-child {
                    display: none !important;
                }
            }

            &::after {
                content: "";
                position: absolute;
                
                width: 100%;
                height: 3px;
                background-color: var(--primary-font-color);
                left: 0;
                bottom: 0;
                transform: scaleY(0);
                transform-origin: bottom;
                transition: transform 0.15s linear;
            }
            
            &:has(a:hover)::after {
                transform: scaleY(1);
            }
            
            a {
                text-decoration: none;
                color: var(--primary-font-color);
                border-bottom: none;
            }
        }
    }
}

// $nav-background-open: rgba(255, 255, 255, 0.928);
// $nav-background-secondary: rgb(228, 240, 255);

// @media (min-width:320px) and (max-device-width : 480px) {
//     nav {
//         position: fixed;
//         width: 45px;
//         height: 100vh;
//         display: flex;
//         flex-direction: column;
//         justify-content: initial;
//         background-color: $nav-background;
//         overflow: hidden;
//         transition: all 0.25s linear;

//         .header {
//             display: flex;
//             justify-content: space-between;

//             .logo {
//                 display: none;
//                 margin-right: 20px;
//             }

//             .open {
//                 display: block;
//                 margin-top: 5px;
//                 padding-left: 5px;
//                 padding-right: 5px;
                
//                 div {
//                     height: 5px;
//                     width: 30px;
//                     background-color: black;
//                     margin-top: 5px;
//                     transform: rotate(0deg) translateX(0px) translateY(0px);
    
//                     transition: transform ease 0.5s;
//                 }
//             }
//         }

//         .links {
//             margin-top: 5px;
//             display: none;
//             width: 100%;
//         }

//         &.active {
//             width: 130px;
//             background-color: $nav-background-open;
            
//             .open {
//                 div:nth-of-type(1) {
//                     display: none;
//                 }

//                 div:nth-of-type(2) {
//                     transform: rotate(45deg) translateX(5px) translateY(1px);
//                 }

//                 div:nth-of-type(3) {
//                     transform: rotate(-45deg) translateX(5px) translateY(-2px);
//                 }
//             }

//             .logo {
//                 display: block;
//             }

//             .links {
//                 display: flex;
//                 width: 100%;
//                 justify-content: space-around;
//                 flex-direction: column;
//                 align-items: center;
//                 font-size: 1.2rem;

//                 & > * {
//                     margin-top: 20px;
//                 }
                
//                 a {
//                     text-decoration: none;
//                     font-family: $main-font-family;
//                     color: var(--primary-font-color);
//                     border-bottom: none;
//                     transition: border-bottom linear 0.1s;
//                     padding-bottom: 0px;
//                     width: 100%;
//                     text-align: center;
        
//                     &:hover {
//                         border-bottom: 3px solid var(--primary-font-color);
//                     }
//                 }
        
//                 .dropdown {
//                     display: flex;
//                     flex-direction: column;
//                     align-items: center;
//                     z-index: 100000000000;
//                     width: 100%;
//                     text-align: center;

//                     & > a {
//                         &:hover {
//                             border-bottom: none !important;
//                         }
//                     }
                    
//                     .items {
//                         display: flex;
//                         flex-direction: column;
//                         opacity: 0%;
//                         height: 0;
//                         width: 0;
//                         transition: all 0.2s ease;
//                         position: absolute;
//                         transform: translateY(-50px);
//                         align-items: center;
                        
//                         a {
//                             margin-top: 20px;
        
//                             &:first-of-type {
//                                 margin-top: 0;
//                             }
//                         }
//                     }
        
//                     &:hover {
//                         background-color: $nav-background-secondary;
                        
//                         .items {
//                             height:fit-content;
//                             width:fit-content;
//                             opacity: 100%;
//                             transform: translateY(28px);
//                             background-color: $nav-background-secondary;
//                             padding: 10px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
.footer {
    display: flex;
    justify-content: space-between;
    font-weight: lighter;
    font-size: 0.75rem;
    width: 80%;
    padding-bottom: 15px;

    position: absolute;
    bottom: 15px;

    left: 50%;
    transform: translateX(-50%);

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgb(181, 181, 181);
        transform: translateY(-20px);
    }

    div {
        color: var(--primary-font-color);

        &:nth-child(1) {
            padding-left: 10px;
        }

        &:nth-child(3) {
            padding-right: 10px;
        }
    }

    @media (min-width:320px) and (max-device-width : 480px) {
        .country {
            display: none;
        }
    }

    .links {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        padding-left: 50px;
        align-items: center;

        .divider {
            width: 1px;
            height: 1rem;
            background-color: var(--primary-font-color);
            margin-left: 10px;
            margin-right: 10px;
        }

        a {
            text-decoration: none;
            color: var(--primary-font-color);
            position: relative;

            &::after {
                content: "";

                position: absolute;
                height: 1px;
                bottom: -3px;
                width: 100%;
                left: 0;
                transform: scaleX(0);
                margin-left: auto;
                margin-right: auto;
                background-color: var(--primary-font-color);
                transition: 0.25s ease-in-out all;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }
    }
}
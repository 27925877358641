@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

#main-entrance {
    width: 100%;

    box-shadow: var(--box-shadow);

    #main-title {
        position: relative;
        width: 100%;
        padding-top: 5rem;
        margin-bottom: 5rem;

        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        text-shadow: var(--box-shadow);
    }

    #application-pics {
        display: block;
        width: 90%;
        margin: 0 auto;
    }
}

.shadow {
    position: relative;
    margin: 50px auto;
    width: fit-content;

    @media (min-width:320px) and (max-device-width : 480px) {
        width: 80%;
        margin: 50px auto;
    }

    & > * {
        opacity: 1;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: calc(100%);
        height: calc(100%);
        background: linear-gradient(45deg,
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,
        );
        z-index: 10;
        background-size: 100%;
        transition: all 0.5s ease;
        animation: shadow 20s linear infinite;
    }

    &::after {
        width: calc(100%);
        height: calc(100%);
        filter: blur(10px);
        opacity: 0.9;
    }
}

#definition-card {
    z-index: 100;
    width: 800px;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    background-color: var(--secondary-background);

    @media (min-width:320px) and (max-device-width : 480px) {
        width: 80vw;
        
        #description {
            font-size: 1.1rem !important;
        }
    }

    #title {
        width: 100%;
        padding: 0px;
        margin: none;
        margin-bottom: 10px;

        text-align: start;

        #title-content {
            width: 100%;
            display: flex;
            font-size: 2.5rem;
            align-items: center;

            #dot {
                margin-left: 3px;
                margin-right: 3px;
                border-radius: 50%;
                background-color: var(--primary-font-color);
                width: 0.35rem;
                height: 0.35rem;
            }
        }

        #sub {
            width: 100%;
            display: block;
            font-size: 1rem;
            font-weight: 100;
        }
    }

    #content {
        padding: 0px;

        #word {
            display: block;
            font-style: italic;
            font-weight: 100;
            color: rgb(227, 227, 227);
            font-size: 1.2rem;
        }

        #description {
            font-size: 1.2rem;
        }
    }
}

@keyframes shadow {
    0% {
        background: linear-gradient(45deg,
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,
        );
    }
    10% {
        background: linear-gradient(45deg,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
        );
    }
    20% {
        background: linear-gradient(45deg,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
        );
    }
    30% {
        background: linear-gradient(45deg,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
        );
    }
    40% {
        background: linear-gradient(45deg,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
        );
    }
    50% {
        background: linear-gradient(45deg,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
        );
    }
    60% {
        background: linear-gradient(45deg,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
        );
    }
    70% {
        background: linear-gradient(45deg,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
            #6d6dff,
        );
    }
    80% {
        background: linear-gradient(45deg,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
            #4444fe,
        );
    }
    90% {
        background: linear-gradient(45deg,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
            #0000ff,
        );
    }
    100% {
        background: linear-gradient(45deg,
            #0000ff,
            #4444fe,
            #6d6dff,
            #aa6dff,
            #e26dff,
            #e7a8f7,
            #e26dff,
            #aa6dff,
            #6d6dff,
            #4444fe,    
        );
    }
}

@keyframes type{
    from { width: 0; } 
} 

#main-points {
    width: 100%;
    
    .point {
        &.show {
            .verbiage {
                .content-container {
                    .header {
                        .name {
                            opacity: 100%;
                        }

                        .title {
                            opacity: 100%;
                            width: 100%;
                            animation: type 2s steps(60, end); 

                            @media (min-width:320px) and (max-device-width : 480px) {
                                animation: none;
                            }
                        }
                    }

                    .content {
                        opacity: 100%;
                    }
                }
            }
        }

        box-shadow: var(--box-shadow);
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        justify-content: space-around;
        background-color: var(--secondary-background);

        @media (min-width:320px) and (max-device-width : 480px) {
            img {
                display: none;
            }
        }
        
        .verbiage {
            padding: 30px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;

            .content-container {
                .header {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;

                    .name {
                        opacity: 0%;
                        transition: opacity 0.5s ease;

                        font-weight: lighter;
                        font-size: 1.2rem;
                    }

                    .title {
                        font-size: 2rem;
                        
                        white-space: nowrap;
                        overflow: hidden;

                        @media (min-width:320px) and (max-device-width : 480px) {
                            white-space:initial;
                            overflow: initial;
                            width: 100% !important;
                        }

                        width: 0%;

                        opacity: 0%;
                        transition: opacity 0.5s ease;
                    }
                }

                .content {
                    font-size: 1.7rem;
                    opacity: 0%;
                    transition: opacity 0.5s ease;
                    font-weight: lighter;
                }
            }

            .link-container {
                flex-grow: 1;
                padding-top: 50px;
                display: flex;
                justify-content: center;
            }
        }

        .image {
            width: 40%;
            object-fit: contain;
            align-self: center;
        }
    }
}

.fomo-section {
    margin-top: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: var(--box-shadow);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-background);

    h1 {
        font-weight: lighter;
        text-align: center;
        font-size: 2rem;
    }

    .fomo-content {
        font-size: 1.7rem;
        display: block;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 20px;
    }
}

.learn-more {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media (min-width:320px) and (max-device-width : 480px) {
        flex-direction: column;
    }

    .card {
        display: flex;
        flex-direction: column;
        width: 20%;
        margin: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        align-items: center;
        text-align: center;
        padding-bottom: 10px;
        box-shadow: var(--box-shadow);
        padding: 5px;
        height: 100%;
        background-color: var(--secondary-background);

        @media (min-width:320px) and (max-device-width : 480px) {
            width: 75%;
        }

        h1 {
            margin-bottom: 0;

            @media (min-width:320px) and (max-device-width : 480px) {
                font-size: 2rem;
            }
        }

        span {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 1.2rem;
            width: 100%;

            @media (min-width:320px) and (max-device-width : 480px) {
                font-size: 1rem;
            }
        }

        a {
            position: relative;

            text-decoration: none;
            color: var(--primary-font-color);

            transition: all linear 0.1s;
            padding-bottom: 10px;

            &::after {
                content: "";

                position: absolute;
                height: 1px;
                bottom: 0.3em;
                width: 100%;
                left: 0;
                transform: scaleX(0);
                margin-left: auto;
                margin-right: auto;
                background-color: var(--primary-font-color);
                transition: 0.25s ease-in-out all;
            }

            &:hover::after {
                transform: scaleX(1);
            }
        }

        img {
            height: 200px;
        }
    }
}
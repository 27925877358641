$bg: rgba(216, 235, 255, 0.922);
$bg2: rgb(204, 220, 239);
$bg3: rgba(194, 210, 229, 0.922);

#story-entrance {
    height: 300px;
    background-color: var(--secondary-background);
    position: relative;
    margin-bottom: 20px;
    box-shadow: var(--box-shadow);
    
    #text {
        position: absolute;
        flex-direction: column;
        text-align: center;
                
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        #title {
            font-size: 2rem;
        }

        #quick-description {
            display: block;
            font-size: 1.8rem;
            margin-top: 15px;
        }
    }
}

.creator {
    display: flex;
    width: 80%;
    margin: 10px auto;
    
    box-shadow: var(--box-shadow);
    background-color: var(--secondary-primary-background);
    border-radius: 5px;
    padding: 10px;
    
    @media (min-width:320px) and (max-device-width : 480px) {
        flex-direction: column;
        align-items: center;
    }

    .description {
        margin-top: 20px;
        margin-left: 50px;
        @media (min-width:320px) and (max-device-width : 480px) {
            margin: 0px;
        }
        
        h1 {
            @media (min-width:320px) and (max-device-width : 480px) {
                font-size: 2rem;
            }
            
            font-size: 3rem;
        }
        
        span {
            font-size: 2rem;
        }
    }

    img {
        @media (min-width:320px) and (max-device-width : 480px) {
            width: 40%;
            object-fit: contain;
        }
    }
}

#timeline {
    margin-bottom: 200px;

    .timepoint {
        width: 7px;
        background-color: var(--secondary-primary-background);
        position: relative;
        margin: 0 auto;
        list-style: none;
        padding-top: 50px;
       
        @media (min-width:320px) and (max-device-width : 480px) {
            margin: initial;
        }

        &:nth-of-type(odd) {
            .content {
                left: 50px;

                &::before {
                    left: -12px;
                    border-width: 8px 16px 8px 0;
                    border-color: transparent var(--secondary-secondary-background);
                }
            }
        }

        &:nth-of-type(even) {
            .content {
                left: calc(-1 * calc(30vw + 40px));

                &::before {
                    right: -12px;
                    border-width: 8px 0px 8px 16px;
                    border-color: transparent var(--secondary-secondary-background);
                }

                @media (min-width:320px) and (max-device-width : 480px) {
                    &::before {
                        left: -12px;
                        border-width: 8px 16px 8px 0;
                        border-color: transparent var(--secondary-primary-background);
                    }
                }
            }
        }

        .point {
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(10px);
            width: 20px;
            height: 20px;
            background-color: var(--secondary-primary-background);
            border-radius: 50%;
        }

        .content {
            @media (min-width:320px) and (max-device-width : 480px) {
                left: 50px !important;
                width: calc(75vw - 10px);
            }

            border-radius: 5px;
            position: relative;
            bottom: 0;
            width: calc(30vw - 10px);
            background-color: var(--secondary-primary-background);
            transition: all 0.5s ease;
            opacity: 0%;
            transform: translateY(100px);

            &.show {
                opacity: 100%;
                transform: translateY(0px);
            }

            &::before {
                content: "";
                position: absolute;
                top: 15px;
                width: 0;
                right: 0;
                border-style: solid;
            }

            .header {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                background-color: var(--secondary-secondary-background);
                box-shadow: 0 3px 2px -2px gray;
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                h1 {
                    display: inline-block;
                    margin-left: 20px;
                }
                
                .year-container {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 1vw;
                    margin-right: 1vw;

                    .year {
                        background-color: var(--secondary-tertiary-background);
                        border-radius: 20px;
                        font-size: 1.2rem;
                        height: fit-content;
                        padding: 5px 15px;
                    }
                }
            }

            .description {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
                display: block;
                font-size: 1.5rem;
            }
        }
    }
}
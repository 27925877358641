.contact-form {
    background-color: var(--accent-background);
    box-shadow: var(--box-shadow);
    
    padding-top: 30px;
    width: 450px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    position: absolute;
    
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width:320px) and (max-device-width : 480px) {
        width: 90%;
    }

    h1 {
        margin: auto;
    }

    .popup {
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
        font-weight: lighter;
    }

    label {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4px;
        font-size: 0.8rem;
    }
    
    .input-highlight {
        width: 80%;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        background-color: var(--secondary-accent-background);
        align-items: center;
        position: relative;

        .icon {
            margin-left: 5px;
            margin-right: 5px;
        }

        input, textarea {
            width: 100%;
            font-size: 1rem;
            background: none;
            outline: none;
            border: none;
            padding: 5px;
            transition: background-color 0.5s ease;
            border-radius: 5px;
        }

        textarea {
            resize: vertical;
            height: 100px;
        }

        &:has(.bad-input) {
            background-color: var(--negative-accent-background);
        }

        &::before {
            content: '';
            position: absolute;
            width: 0%;
            margin-left: auto;
            margin-right: auto;
            height: 4px;
            background-color: var(--primary-font-color);
            left: 0px;
            bottom: -2px;
            transition: width .3s ease-in-out;
        }

        &:has(input:focus,textarea:focus)::before {
            width: 100%;
        }
    }

    input[type=submit] {
        margin: 20px;
        font-size: 1rem;
        padding: 5px;
        border: none;
        background-color: var(--secondary-accent-background);
        width: 40%;
        border-radius: 15px;
        transition: all .3s;
        margin-left: auto;
        margin-right: auto;

        &:disabled {
            background-color: var(--negative-accent-background);
            cursor: no-drop;

            &:hover {
                box-shadow: none;
            }
        }

        &:hover {
            box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
        }

        &:active {
            background-color: var(--tertiary-accent-background);
        }
    }
}
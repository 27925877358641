.header-bar {
    padding-top: 40px;
   
    h1 {
        text-align: center;
        font-size: 3rem;
    }

    hr {
        width: 350px;
        border: none;
        height: 3px;
        background-color: black;
    }
}

.social-card {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    background-color: var(--tertiary-background);
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;

    box-sizing: border-box;

    @media (min-width:320px) and (max-device-width : 480px) {
        flex-direction: column !important;
    }

    &:nth-of-type(odd) {
        flex-direction: row-reverse;
    }

    .details {
        width: 50%;
        padding: 25px;
        box-sizing: border-box;
        
        @media (min-width:320px) and (max-device-width : 480px) {
            width: 100%;
        }

        .card-title {
            display: block;
            font-size: 2rem;
            margin-bottom: 15px;
        }

        .card-details {
            display: block;
            font-size: 1.7rem;
            font-weight: lighter;
        }

        .social-link {
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;
            display: inline-flex;
            align-items: center;
            position: relative;

            .icon {
                font-size: 1.3rem;
                
            }

            .fa-youtube > path, .fa-reddit > path {
                color: var(--primary-font-color);
                transition: color 0.2s ease-in-out;
            }

            &:hover .fa-youtube > path, &:hover .fa-reddit > path {
                color: red;
            }
    
            .fa-instagram > path {
                color: var(--primary-font-color);
                transition: color 0.2s ease-in-out;
            }

            &:hover .fa-instagram > path {
                color: rgb(168, 5, 168);
            }
    
            &:has(.fa-youtube, .fa-reddit) {
                &::after {
                    background-color: red;
                }
    
                &:hover {
                    a {
                        color: red;
                    }
                }
            }
    
            &:has(.fa-instagram) {
                &::after {
                    background-color: rgb(168, 5, 168);
                }
    
                &:hover {
                    a {
                        color: rgb(168, 5, 168);
                    }
                }
            }
    
            a {
                text-decoration: none;
                margin-left: 10px;
                font-size: 1.3rem;
                color: var(--primary-font-color);
                transition: color 0.2s ease-in-out;
            }
    
            &::after {
                content: "";
                width: 0px;
                transition: width 0.2s ease-in-out;
            }
    
            &:hover {
                &::after {
                    content: "";
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    right: 0;
                    bottom: -5px;
                    left: 0;
                }
            }
        }
    }

    .embed {
        iframe {
            min-height: 300px;
            width: 100%;
        }
        
        height: 100%;
        width: 49%;

        @media (min-width:320px) and (max-device-width : 480px) {
            width: 100%;
        }
    }
}
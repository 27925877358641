.nav-link {
    text-decoration: none;
    color: var(--secondary-font-color);
    position: relative;

    &::after {
        content: "";

        position: absolute;
        height: 1px;
        bottom: -3px;
        width: 100%;
        left: 0;
        transform: scaleX(0);
        margin-left: auto;
        margin-right: auto;
        background-color: var(--primary-font-color);
        transition: 0.25s ease-in-out all;
    }

    &:hover {
        color: var(--primary-font-color);

        &::after {
            transform: scaleX(1);
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    --primary-font-color: white;
    --secondary-font-color: rgb(225, 225, 225);
    
    --background-opaque: rgba(8, 8, 8, 0.3);
    --primary-background: black;
    --secondary-background: rgb(8, 8, 8);
    --tertiary-background: rgb(13, 13, 14);

    --secondary-primary-background: rgb(22, 22, 23);
    --secondary-secondary-background: rgb(27, 27, 29);
    --secondary-tertiary-background: rgb(35, 35, 38);

    --accent-background: rgb(36, 36, 42);
    --secondary-accent-background: rgb(50, 50, 59);
    --tertiary-accent-background: rgb(60, 60, 70);
    --negative-accent-background: rgb(78, 58, 58);
    
    --box-shadow: rgba(184, 233, 251, 0.2) 0px 0px 10px 0px;
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: var(--primary-background);
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    min-height: 100vh;
}

body::after {
    content: '';
    display: block;
    height: 80px;
}

* {
    color: var(--primary-font-color);
    font-family: "Open Sans", sans-serif;
    scroll-margin-top: 20px;
}
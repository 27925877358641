#feature-entrance {
    width: 100%;
    height: 35vw;
    overflow-y: hidden;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    background: linear-gradient(var(--tertiary-background), var(--secondary-background));

    @media (min-width:320px) and (max-device-width : 480px) {
        height: 60vw;
    }

    #content {
        position: absolute;
        top: 0%;
        z-index: 1000;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        #title {
            display: block;
            font-size: 3rem;
            margin-left: 50px;
            text-align: center;
        }

        #image {
            width: 50%;
        }

        @media (min-width:320px) and (max-device-width : 480px) {
            flex-direction: column;
            #title {
                margin-top: 20px;
                font-size: 2rem;
                width: 100%;
                text-align: center;
                margin-left: 0px;
            }
        }
    }
}

.pekoscript {
    background-color: var(--secondary-background);
    box-shadow: var(--box-shadow);
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;

    .header {
        margin-bottom: 30px;
        
        h1 {
            padding-top: 20px;
            text-align: center;
            width: 100%;
            font-size: 3rem;

            @media (min-width:320px) and (max-device-width : 480px) {
                font-size: 2rem;
            }
        }

        span {
            text-align: center;
            font-size: 2rem;
            width: 100%;
            display: block;
            
            @media (min-width:320px) and (max-device-width : 480px) {
                font-size: 1.4rem;
            }
        }
    }

    .feature-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .feature {
            position: relative;
            background-color: var(--tertiary-background);
            width: 45%;
            display: flex;
            padding: 10px;
            margin: 10px;
            transform: scale(1);
            transition: all 0.5s ease;
            box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 2px 0px;

            @media (min-width:320px) and (max-device-width : 480px) {
                width: 90%;
                margin-bottom: 40px;
            }

            .top-space {
                height: 1.5rem;
            }

            @media (min-width:320px) and (max-device-width : 480px) {
                &.show {
                    transform: scale(1.1);
                    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;

                    .feature-content {
                        span {
                            opacity: 100;
                        }

                        h1 {
                            top: 0%;
                            transform: translateY(15%);
                        }
                    }
                }
            }

            @media not ((min-width:320px) and (max-device-width : 480px)) {
                &:hover {
                    transform: scale(1.1);
                    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;

                    .feature-content {
                        span {
                            opacity: 100;
                        }

                        h1 {
                            top: 0%;
                            transform: translateY(15%);
                        }
                    }
                }
            }

            .feature-content {
                h1 {
                    width: 100%;
                    text-align: center;
                    padding: 0px;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.5s ease;
                }

                span {
                    text-align: center;
                    opacity: 0;
                    font-size: 1.5rem;
                    display: block;
                    margin-top: 15px;
                    transition: opacity 0.5s ease;
                }
            }
        }
    }
}

.cross {
    background-color: var(--secondary-background);
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;

    .header {
        text-align: center;
        font-size: 3rem;
        padding-top: 20px;
    }
    
    .content {
        display: flex;
        align-items: center;

        @media (min-width:320px) and (max-device-width : 480px) {
            flex-direction: column;

            span {
                text-align: center;
                font-size: 1.5rem !important;
            }
        }
        
        img {
            width: 50%;
            object-fit: contain;
        }

        span {
            padding: 10px;
            font-size: 2rem;
        }
    }
}

.devex {
    margin-top: 50px;
    box-shadow: var(--box-shadow);
    background-color: var(--secondary-background);
    padding-top: 10px;

    .header {
        text-align: center;
        font-size: 3rem;

        @media (min-width:320px) and (max-device-width : 480px) {
            font-size: 2.5rem;
        }
    }

    .description {
        font-size: 2rem;
        text-align: center;
        display: block;
        padding-bottom: 10px;

        @media (min-width:320px) and (max-device-width : 480px) {
            font-size: 2rem;
        }
    }

    .devtools {
        @media (min-width:320px) and (max-device-width : 480px) {
            margin-bottom: 60px;
        }

        .devtool {
            background-color: var(--tertiary-background);
            display: flex;
            margin-top: 20px;
            box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 5px 0px;

            @media (min-width:320px) and (max-device-width : 480px) {
                flex-direction: column;
                text-align: center;
                padding-bottom: 20px;

                img {
                    display: none;
                }
            }

            .content {
                @media not ((min-width:320px) and (max-device-width : 480px)) {
                    flex-grow: 1;

                    &:nth-child(odd) {
                        padding-left: 30px;
                    }
    
                    &:nth-child(even) {
                        padding-right: 40px;
                    }
                }

                .section1 {
                    margin-top: 10%;
                    
                    h1 {
                        font-size: 2rem;
                    }

                    span {
                        display: block;
                        font-size: 1.5rem;
                        margin-bottom: 10%;
                    }
                }
                
                .section2 {
                    position: relative;
                    
                    a {
                        position: absolute;
                        font-size: 1.2rem;
                        left: 50%;
                        transform: translateX(-50%);

                        @media (min-width:320px) and (max-device-width : 480px) {
                            position: initial;
                            display: block;
                            transform: translateX(0%);
                            left: 0%;
                            margin: auto;

                            &::after {
                                display: none !important;
                            }
                        }
                    }
                }
            }

            img {
                width: 50%;
            }
        }
    }
}

#top-row {
    display: flex;
    justify-content: space-evenly;
       
    @media (min-width:320px) and (max-device-width : 480px) {
        align-items: center;
        flex-direction: column;
    }

    .feature-section {
        border-radius: 5px;
        width: 25%;
        padding: 10px;
        padding-bottom: 0px;
        box-shadow: var(--box-shadow);

        @media (min-width:320px) and (max-device-width : 480px) {
            margin-bottom: 20px;
            width: 90%;
        }

        text-align: center;

        h1 {
            font-size: 1.7rem;
            padding-bottom: 20px;
        }

        span {
            font-size: 1.5rem;
        }
    }

    margin-bottom: 40px;
}

#banner {
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;

    @media (min-width:320px) and (max-device-width : 480px) {
        align-items: center;
        flex-direction: column;
    }

    .part {
        width: 40%;
        display: flex;
               flex-direction: column;
        align-items: center;
        
        @media (min-width:320px) and (max-device-width : 480px) {
            width: 100%;
        }
        
        & > div {
            margin-top: 20px;
            text-align: center;
            
            span {
                font-size: 1.5rem;
            }

            ul {
                margin-top: 30px;
                text-align: initial;
                margin-left: auto;
                margin-right: auto;
                width: fit-content;

                li {
                    font-size: 1.2rem;
                    width: 100%;
                    
                    .parts {
                        display: flex;
                        justify-content: space-between;
                        .part1 {
                            font-size: 1.2rem;
                            display: inline-block; 
                        }
                        
                        .part2 {
                            display: inline-block;
                            
                            & > * {
                                display: inline-block;
                            }
    
                            .arrow {
                                width: 0;
                                height: 0;
                                border-top: 5px solid transparent;
                                border-bottom: 5px solid transparent;
                                border-left: 5px solid var(--primary-font-color);
                                margin-left: 10px;
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
        }

        img {
            margin-top: 30px;
            width: 30%;
        }
    }
}

#bottom-row {
    margin-bottom: 60px;
    
    #title {
        text-align: center;
        padding: 0px 50px 10px 50px;
        border-bottom: 3px solid black;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    #cards {
        display: flex;
        justify-content: space-evenly;
       
        @media (min-width:320px) and (max-device-width : 480px) {
            flex-direction: column;
            align-items: center;
        }
        
        .card {
            border-radius: 5px;
            width: 25%;
            padding: 10px;
            padding-bottom: 0px;
            box-shadow: var(--box-shadow);
            padding-bottom: 10px;

            @media (min-width:320px) and (max-device-width : 480px) {
                width: 90%;
                margin-bottom: 10px;
            }

            text-align: center;

            h1 {
                font-size: 1.7rem;
                padding-bottom: 20px;
                border-bottom: 2px solid rgb(98, 119, 119);
            }

            span {
                font-size: 1.5rem;
            }
        }
    }
}

#last-row {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: var(--box-shadow);

    @media (min-width:320px) and (max-device-width : 480px) {
        flex-direction: column-reverse;
    }
    
    img {
        width: 50%;

        @media (min-width:320px) and (max-device-width : 480px) {
            width: 100%;
        }
    }
    
   
    #description {
        h1 {
            font-size: 2.5rem;
            text-align: center;
        }
        
        span {
            font-size: 2rem;
        }
    }
}
.nav-button {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 100;

    height: 1.5rem;
    padding: 5px;
    border-radius: 5px;

    background-color: var(--accent-background);

    color: var(--secondary-font-color);
    text-decoration: none;

    transition: all linear 0.1s;

    .background {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: var(--secondary-accent-background);
        position: absolute;
        top: 0px;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.25s ease;
    }

    .text {
        z-index: 0;
    }


    &:hover {
        .background {
            transform: scaleX(1);
        }
    }
}